import {LogOut} from "@/Api/Basics";
import Storage from "@/util/Storage"

// 退出
export function dropOut() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( LogOut({ userId,token }).then(res=>{
        if ( !!!res )Storage.ClearAll && Storage.ClearAll();
        return res.data
    }) )
}
